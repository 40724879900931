<template>
    <div class="">
        <el-button type="primary"  plain size="small" @click="_onclick">{{title}}</el-button>
        <el-drawer :title="title" size="90%" :visible.sync="drawer" direction="rtl" append-to-body destroy-on-close>
            <div style="display:flex;justify-content:center;align-items:center;gap:20px;margin-bottom:20px">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox :label="0">温度曲线</el-checkbox>
                    <el-checkbox :label="1">湿度曲线</el-checkbox>
                    <el-checkbox :label="2">运动轨迹</el-checkbox>
                </el-checkbox-group>
                <el-button type="primary"
                 v-permission="'Order:Pdf'"
                :disabled="checkList.length>0 && seriesData.length>0?false:true" size="small" @click="canvasIt">导出PDF</el-button>    
            </div>
            <div ref="html2canvasRef" class="y-dialog-body" style="">
                <div v-show="checkList.includes(0)" id="highcharts_t" style="height:400px"></div>
                <div v-show="checkList.includes(1)" id="highcharts_h" style="height:400px"></div>
                <h3 v-show="checkList.includes(2)" style="text-align:center;padding-bottom:10px;background:#fff">运动轨迹</h3>
                <div v-show="checkList.includes(2)" id="containers" ref="mapContainers" style="width:100%;height:600px;"></div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
var Highcharts = require('highcharts');
require('highcharts/modules/stock')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
import draggable from 'highcharts/modules/draggable-points.js'
import html2canvas from "html2canvas";
import Axios from 'axios'
import {
    getdevData,OrderPDF,
} from '@/api/instrument/Theorder.js'
export default {
    props: {
        row: {
            type: Object,
            default: () => ({})
        },
    },
    name:"",
    data() {
        return {
            checkList: [0],
            title:'预览',
            drawer:false,
            chart:null,
            seriesData:[],//温度数据
            seriesData_h:[],//湿度数据

            path: [], // 运动轨迹数据
        }
    },
    methods: {
        canvasIt() {//截图
            const mapsRef = this.$refs.html2canvasRef
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });



            html2canvas(mapsRef, {
                    allowTaint: false, //允许跨域截图
                    backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
                    useCORS: true, //支持图片跨域
                    // scale: 1.5, //设置放大的倍数
                }).then(canvas => {

                    // // 获取原图片的宽度和高度
                    // const originalWidth = canvas.width;
                    // const originalHeight = canvas.height;
                    // // 设置目标宽度
                    // const targetWidth = 595;
                    // // 计算高度自适应的值
                    // const targetHeight = (targetWidth / originalWidth) * originalHeight;

                    // // 创建一个新的 canvas 用于绘制缩放后的图片
                    // const scaledCanvas = document.createElement('canvas');
                    // const ctx = scaledCanvas.getContext('2d');
                    // scaledCanvas.width = targetWidth;
                    // scaledCanvas.height = targetHeight;
                    // console.log(canvas,scaledCanvas.width,scaledCanvas.height,ctx)
                    // // 将原图片绘制到新的 canvas 上
                    // ctx.drawImage(canvas, 0, 0, originalWidth, originalHeight, 0, 0, targetWidth, targetHeight);
                    // let img = new Image();
                    // img.src = scaledCanvas.toDataURL('image/png');
                    // let a = document.createElement('a');
                    // a.href = img.src;
                    // a.download = new Date().getTime()
                    // a.click();
                    // return
                    let img = new Image();
                    img.src = canvas.toDataURL('image/png'); // toDataURL :图片格式转成 base64
                    // let a = document.createElement('a');
                    // a.href = img.src;
                    // a.download = new Date().getTime()
                    // a.click();
                    OrderPDF({
                        T_id:this.row.Id,
                        images:img.src 
                    }).then(res=>{
                        if(res.data.Data){
                            Axios({
                                method: 'get',
                                url: res.data.Data,
                                responseType: 'blob'
                            }).then(resBlob => {
                                const FileSaver = require('file-saver')
                                const blob = new Blob([resBlob.data], {
                                    type: 'application/pdf;charset=utf-8'
                                }) // 此处type根据你想要的
                                FileSaver.saveAs(blob,new Date().getTime()) 
                                loading.close();
                                this.$message.success('导出成功')
                            });
                        }
                    })
                })
            
        },
        async _onclick(){
            let {Id:T_id} = this.row
            this.drawer = true;
            const result = await getdevData({T_id})
            let res = result.data.Data || []
            if (res.length === 0) {
                return;
            }
            let arr = result.data.Data.reverse()
            this.seriesData = [this.transformData(arr,['T_t','T_tl','T_tu'])]
            this.seriesData_h = [this.transformData(arr,['T_rh','T_rhl','T_rhu'])]
            this.path = arr.map(item=>[Number(item.T_site.split(',')[0]),Number(item.T_site.split(',')[1])])
            console.log(this.seriesData,this.seriesData_h,this.path);
            console.log(this.seriesData[0].data[0][3]);
            
            Highcharts.setOptions({
                lang: {
                    resetZoom: '重置缩放比例',
                    resetZoomTitle: '重置缩放比例'
                }
            });
            this.$nextTick(()=>{
                this.getChart_t()	
                this.getChart_h()	
                this.initMap()
            })
        },
        transformData(arr,key) {
            if (arr.length === 0) {
                return {
                    name: '',
                    data: []
                };
            }
            const name = arr[0].T_name;
            const data = arr.map(item => [Date.parse(item.T_time),item[key[0]],item[key[1]],item[key[2]]]);
            return {
                name,
                data
            };
        },

        initMap() {
            // 初始化地图
            this.map = new AMap.Map('containers', {
                zoom: 13,
                resizeEnable: true,
                center: this.path[0],
                 WebGLParams: {
                        preserveDrawingBuffer: true
                    },
            });

            // 绘制运动轨迹
            this.polyline = new AMap.Polyline({
                path: this.path,
                strokeColor: '#FF33FF', // 线颜色
                strokeWeight: 5, // 线宽
                strokeOpacity: 1, // 透明度
                lineJoin: 'round', // 折线拐点连接处样式
                lineCap: 'round', // 折线两端线帽样式
                zIndex: 50
            });
            this.polyline.setMap(this.map);


      // 添加终点标记
      this.endMarker = new AMap.Marker({
        position: this.path[this.path.length - 1],
        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png',
        offset: new AMap.Pixel(-10, -34)
      });
      this.endMarker.setMap(this.map);
        },
        getChart_t() {
            console.log('触发');
            // if (this.chart) {
            //     this.chart.destroy(); // 销毁旧的图表实例
            // }

            let self = this
            // 显示带导航条的 Highcharts.stockChart('highcharts', {
            this.chart = Highcharts.chart('highcharts_t', {
                chart: {
                    zoomType: 'x', //x反向放大
                    type: 'spline', //线样式
                    panning: true, //是否移动
                    panKey: 'ctrl' //按住ctrl可移动
                },
                time: {
                    useUTC: true
                },
                boost: {
                    useGPUTranslations: true
                },
                lang: {
                    viewFullscreen: "全屏",
                    exitFullscreen: "退出全屏",
                    printChart: "打印图表",
                    downloadJPEG: "下载JPEG图片",
                    downloadPDF: "下载PDF文件",
                    downloadPNG: "下载PNG文件",
                    downloadSVG: "下载SVG文件",
                },
                title: {
                    text: "冷链温度曲线图"
                },
                xAxis: {
                    title: {text: '时间'},
                    type: 'datetime',
                    // // 添加 dateTimeLabelFormats 选项来指定时间格式
                    // dateTimeLabelFormats: {
                    //     millisecond: '%Y-%m-%d %H:%M:%S.%L',
                    //     second: '%Y-%m-%d %H:%M:%S',
                    //     minute: '%Y-%m-%d %H:%M',
                    //     hour: '%Y-%m-%d %H:%M',
                    //     day: '%Y-%m-%d',
                    //     week: '%Y-%m-%d',
                    //     month: '%Y-%m',
                    //     year: '%Y'
                    // }
                },
                
                yAxis: {
                    title: {
                        text: '温度℃'
                    },
                    min: Math.min(...this.seriesData[0].data.map(item => item[2])) - 5, // 动态设置y轴最小值
                    max: Math.max(...this.seriesData[0].data.map(item => item[3])) + 5, // 动态设置y轴最大值
                    plotLines:[{
                    	color: '#FF1D00', //线的颜色，定义为红色
                    	dashStyle: 'solid', //默认值，这里定义为实线
                    	value: this.seriesData[0].data[0][3], //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线  
                    	width: 2, //标示线的宽度，2px
                    	label: {
                    		text: `温度上限（${this.seriesData[0].data[0][3]}℃）`, //标签的内容
                    		align: 'right', //标签的水平位置，水平居左,默认是水平居中center
                    		// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
                    	},
                    },{
                    	color: '#FF1D00', //线的颜色，定义为红色
                    	dashStyle: 'solid', //默认值，这里定义为实线
                    	value: self.seriesData[0].data[0][2], //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线  
                    	width: 2, //标示线的宽度，2px
                    	label: {
                    		text: `温度下限（${self.seriesData[0].data[0][2]}℃）`, //标签的内容
                    		align: 'right', //标签的水平位置，水平居左,默认是水平居中center
                    		// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
                    	},
                    }]
                },
                credits: {//去掉水印
                    enabled: false
                },
                subtitle: {
                    useHTML: false,
                },
                plotOptions: {
                    series: {
                        // compare: 'percent',
                        showInNavigator: true
                    }
                },
                tooltip: {
                    valueSuffix: '℃',
                    // backgroundColor: '#3d4b61', // 背景颜色
                    // style: { // 文字内容相关样式
                    //     color: "#fff",
                    //     fontSize: "12px",
                    //     fontWeight: "blod",
                    //     fontFamily: "Courir new"
                    // },
                    crosshairs: [ //显示鼠标放上去的时候显示的提示标线
                        {
                            width: 1,
                            color: '#ccc'
                        },
                    ],
                    // 添加 formatter 函数来格式化时间
                    formatter: function () {
                        const date = new Date(this.x);
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        // 直接访问 valueSuffix
                        return `<b>${this.series.name}</b><br/>${formattedDate}: ${this.y}${this.series.chart.options.tooltip.valueSuffix}`;
                    },
                },
                series:this.seriesData,
                responsive: {
                    rules: [{ // 在图表小于 500px 的情况下关闭图例
                        condition: { // 响应条件
                            maxWidth: 500
                        },
                        chartOptions: { // 响应内容
                            legend: {
                                enabled: false
                            }
                        }
                    }]
                }
            });
        },
        getChart_h() {
            // if (this.chart) {
            //     this.chart.destroy(); // 销毁旧的图表实例
            // }
            // 显示带导航条的 Highcharts.stockChart('highcharts', {
            this.chart = Highcharts.chart('highcharts_h', {
                chart: {
                    zoomType: 'x', //x反向放大
                    type: 'spline', //线样式
                    panning: true, //是否移动
                    panKey: 'ctrl' //按住ctrl可移动
                },
                time: {
                    useUTC: true
                },
                boost: {
                    useGPUTranslations: true
                },
                lang: {
                    viewFullscreen: "全屏",
                    exitFullscreen: "退出全屏",
                    printChart: "打印图表",
                    downloadJPEG: "下载JPEG图片",
                    downloadPDF: "下载PDF文件",
                    downloadPNG: "下载PNG文件",
                    downloadSVG: "下载SVG文件",
                },
                title: {
                    text: "冷链湿度曲线图"
                },
                xAxis: {
                    title: {text: '时间'},
                    type: 'datetime',
                },
                
                yAxis: {
                    title: {
                        text: '湿度%'
                    },
                    min: Math.min(...this.seriesData_h[0].data.map(item => item[2])) - 5, // 动态设置y轴最小值
                    max: Math.max(...this.seriesData_h[0].data.map(item => item[3])) + 5, // 动态设置y轴最大值
                    plotLines:[{
                    	color: '#FF1D00', //线的颜色，定义为红色
                    	dashStyle: 'solid', //默认值，这里定义为实线DashDot
                    	value: this.seriesData_h[0].data[0][3], //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线  
                    	width: 2, //标示线的宽度，2px
                    	label: {
                    		text: `湿度上限（${this.seriesData_h[0].data[0][3]}%）`, //标签的内容
                    		align: 'right', //标签的水平位置，水平居左,默认是水平居中center
                    		// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
                    	},
                    },{
                    	color: '#FF1D00', //线的颜色，定义为红色
                    	dashStyle: 'solid', //默认值，这里定义为实线
                    	value: this.seriesData_h[0].data[0][2], //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线  
                    	width: 2, //标示线的宽度，2px
                    	label: {
                    		text: `湿度下限（${this.seriesData_h[0].data[0][2]}%）`, //标签的内容
                    		align: 'right', //标签的水平位置，水平居左,默认是水平居中center
                    		// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
                    	},
                    }]
                },
                credits: {//去掉水印
                    enabled: false
                },
                subtitle: {
                    useHTML: false,
                },
                plotOptions: {
                    series: {
                        // compare: 'percent',
                        showInNavigator: true
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    // backgroundColor: '#3d4b61', // 背景颜色
                    // style: { // 文字内容相关样式
                    //     color: "#fff",
                    //     fontSize: "12px",
                    //     fontWeight: "blod",
                    //     fontFamily: "Courir new"
                    // },
                    crosshairs: [ //显示鼠标放上去的时候显示的提示标线
                        {
                            width: 1,
                            color: '#ccc'
                        },
                    ],
                    // 添加 formatter 函数来格式化时间
                    formatter: function () {
                        const date = new Date(this.x);
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        // 直接访问 valueSuffix
                        return `<b>${this.series.name}</b><br/>${formattedDate}: ${this.y}${this.series.chart.options.tooltip.valueSuffix}`;
                    },
                },
                series:this.seriesData_h,
                responsive: {
                    rules: [{ // 在图表小于 500px 的情况下关闭图例
                        condition: { // 响应条件
                            maxWidth: 500
                        },
                        chartOptions: { // 响应内容
                            legend: {
                                enabled: false
                            }
                        }
                    }]
                }
            });
        },
    },
}
</script>
<style lang="scss">
    // .y-dialog{
    //    overflow-y:auto;
    //    margin-top:10vh !important;
    //    height:80vh;
       
    // }
</style>